<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 my-visits-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("my-visits", "visits", "visits") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('my-visits', 'visits', 'search')"
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>

    <ul class="clebex-item-section pill" v-if="visits && visits.length">
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="visit in visitsList"
        :key="visit.id"
      >
        <router-link
          :to="{
            name: 'r_view-my-visit',
            params: { visitId: visit.id }
          }"
          class="clebex-item-content-wrapper"
        >
          <span class="label">
            <span class="text">
              <span class="highlight">{{
                `${visit.host.first_name} ${visit.host.last_name}, `
              }}</span
              ><br />
              {{
                `${displayDate(visit.datetime_from)} - ${displayDate(
                  visit.datetime_to
                )}`
              }}
            </span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </router-link>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";

export default {
  name: "Receptions",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      searchQuery: "",
      datetime_from: DateTime.now().toFormat("yyyy-MM-dd HH:mm")
    };
  },
  created() {
    this.getVisits({ datetime_from: this.datetime_from });
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("myVisits", ["visits"]),
    visitsList() {
      const query = this.searchQuery;
      if (this.visits && this.visits.length) {
        if (query && query.length > 1) {
          return this.visits.filter(
            item =>
              `${item.host.first_name} ${item.host.last_name}`
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              this.displayDate(item.datetime_from)
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              this.displayDate(item.datetime_to)
                .toLowerCase()
                .includes(query.toLowerCase())
          );
        } else {
          return this.visits;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("myVisits", ["getVisits"]),
    displayDate(date) {
      return formatDate(
        date,
        `${this.globalDateFormat} ${this.globalTimeFormat}`
      );
    }
  }
};
</script>
